const Filter2Icon = () => {
  return (
    <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.45406 5.04197C4.45406 6.35544 5.52237 7.40062 6.81271 7.40062C8.10314 7.40062 9.17135 6.35533 9.17135 5.04197C9.17135 3.7285 8.10305 2.68333 6.81271 2.68333C5.49924 2.68333 4.45406 3.75163 4.45406 5.04197ZM5.58744 5.06314C5.58744 4.37602 6.14375 3.83787 6.81271 3.83787C7.48161 3.83787 8.03798 4.37679 8.03798 5.06314C8.03798 5.75026 7.48167 6.28841 6.81271 6.28841C6.12559 6.28841 5.58744 5.7321 5.58744 5.06314Z" fill="#344054" stroke="#344054" stroke-width="0.3" />
      <path d="M8.60451 5.60852H13.7499C14.0622 5.60852 14.3165 5.35421 14.3165 5.04188C14.3165 4.72955 14.0622 4.47523 13.7499 4.47523H8.60451C8.29219 4.47523 8.03787 4.72954 8.03787 5.04188C8.03787 5.3542 8.29218 5.60852 8.60451 5.60852Z" fill="#344054" stroke="#344054" stroke-width="0.3" />
      <path d="M3.24996 5.60852H5.04187C5.3542 5.60852 5.60852 5.35421 5.60852 5.04188C5.60852 4.72955 5.35421 4.47523 5.04187 4.47523H3.24996C2.93764 4.47523 2.68331 4.72954 2.68331 5.04188C2.68331 5.3542 2.93763 5.60852 3.24996 5.60852Z" fill="#344054" stroke="#344054" stroke-width="0.3" />
      <path d="M12.5468 11.3154L12.5468 11.3154V11.3128C12.5468 9.99936 11.4785 8.95419 10.1882 8.95419C8.87473 8.95419 7.82955 10.0225 7.82955 11.3128C7.82955 12.6263 8.89785 13.6715 10.1882 13.6715C11.4802 13.6715 12.5243 12.6236 12.5468 11.3154ZM8.96293 11.334C8.96293 10.6469 9.51923 10.1087 10.1882 10.1087C10.852 10.1087 11.3934 10.643 11.4135 11.336C11.4124 12.0222 10.8565 12.5593 10.1882 12.5593C9.50108 12.5593 8.96293 12.003 8.96293 11.334Z" fill="#344054" stroke="#344054" stroke-width="0.3" />
      <path d="M3.25161 11.9005H8.39699C8.7095 11.9005 8.96363 11.6452 8.96363 11.3339C8.96363 11.0216 8.70932 10.7673 8.39699 10.7673H3.25161C2.93929 10.7673 2.68496 11.0216 2.68496 11.3339C2.68496 11.6462 2.93927 11.9005 3.25161 11.9005Z" fill="#344054" stroke="#344054" stroke-width="0.3" />
      <path d="M11.9589 11.9005H13.7508C14.0633 11.9005 14.3174 11.6452 14.3174 11.3339C14.3174 11.0216 14.0631 10.7673 13.7508 10.7673H11.9589C11.6466 10.7673 11.3922 11.0216 11.3922 11.3339C11.3922 11.6462 11.6465 11.9005 11.9589 11.9005Z" fill="#344054" stroke="#344054" stroke-width="0.3" />
    </svg>

  );
};

export { Filter2Icon };
