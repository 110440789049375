const PrinterIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3031_2065)">
                <path d="M12 4.66732V3.46732C12 2.72058 12 2.34721 11.8547 2.062C11.7269 1.81111 11.5229 1.60714 11.272 1.47931C10.9868 1.33398 10.6134 1.33398 9.86671 1.33398H6.13337C5.38664 1.33398 5.01327 1.33398 4.72805 1.47931C4.47717 1.60714 4.2732 1.81111 4.14537 2.062C4.00004 2.34721 4.00004 2.72058 4.00004 3.46732V4.66732M4.00004 12.0007C3.38006 12.0007 3.07007 12.0007 2.81574 11.9325C2.12555 11.7476 1.58646 11.2085 1.40152 10.5183C1.33337 10.264 1.33337 9.95397 1.33337 9.33398V7.86732C1.33337 6.74721 1.33337 6.18716 1.55136 5.75934C1.74311 5.38301 2.04907 5.07705 2.42539 4.8853C2.85322 4.66732 3.41327 4.66732 4.53337 4.66732H11.4667C12.5868 4.66732 13.1469 4.66732 13.5747 4.8853C13.951 5.07705 14.257 5.38301 14.4487 5.75934C14.6667 6.18716 14.6667 6.74721 14.6667 7.86732V9.33398C14.6667 9.95397 14.6667 10.264 14.5986 10.5183C14.4136 11.2085 13.8745 11.7476 13.1843 11.9325C12.93 12.0007 12.62 12.0007 12 12.0007M10 7.00065H12M6.13337 14.6673H9.86671C10.6134 14.6673 10.9868 14.6673 11.272 14.522C11.5229 14.3942 11.7269 14.1902 11.8547 13.9393C12 13.6541 12 13.2807 12 12.534V11.4673C12 10.7206 12 10.3472 11.8547 10.062C11.7269 9.81111 11.5229 9.60714 11.272 9.47931C10.9868 9.33398 10.6134 9.33398 9.86671 9.33398H6.13337C5.38664 9.33398 5.01327 9.33398 4.72805 9.47931C4.47717 9.60714 4.2732 9.81111 4.14537 10.062C4.00004 10.3472 4.00004 10.7206 4.00004 11.4673V12.534C4.00004 13.2807 4.00004 13.6541 4.14537 13.9393C4.2732 14.1902 4.47717 14.3942 4.72805 14.522C5.01327 14.6673 5.38664 14.6673 6.13337 14.6673Z" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3031_2065">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export { PrinterIcon };