const WarnIcon = () => {
    return (
        <svg
            width="22"
            height="22"
            viewBox="0 0 11 11"
            fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3438_15542)">
                <path d="M5.49985 4.12169V5.95502M5.49985 7.78835H5.50443M4.8652 1.78039L1.09546 8.29176C0.886368 8.65292 0.781821 8.8335 0.797273 8.98171C0.81075 9.11099 0.878478 9.22846 0.983601 9.30489C1.10412 9.39252 1.31278 9.39252 1.73011 9.39252H9.26959C9.68691 9.39252 9.89557 9.39252 10.0161 9.30489C10.1212 9.22846 10.1889 9.11099 10.2024 8.98171C10.2179 8.8335 10.1133 8.65292 9.90423 8.29176L6.13449 1.78039C5.92615 1.42053 5.82198 1.24059 5.68607 1.18016C5.56752 1.12745 5.43218 1.12745 5.31363 1.18016C5.17772 1.24059 5.07355 1.42053 4.8652 1.78039Z" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3438_15542">
                    <rect width="11" height="11" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export { WarnIcon };