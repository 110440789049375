export enum EExportType {
  EXCEL = 0,
  PDF = 1,
}

export enum EReportType {
  TransactionJournal = 0,
  DailyPayments,
  AccountingTransaction,
  AdjustmentsReport,
}
