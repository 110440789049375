export enum ETabsIcon {
    FINISHING = "Finishing",
    PRICING = "pricing",
    PRINTING_DETAILS = "PrintingDetails",
}

export enum ModalType {
    DELETE = "delete",
    DUPLICATE = "duplicate",
    EDIT = "edit",
    ADD = "add",
    WARNING = "warning",
  }