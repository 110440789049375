const ProfitIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.3334 13.6673H4.66671C2.66671 13.6673 1.33337 12.6673 1.33337 10.334V5.66732C1.33337 3.33398 2.66671 2.33398 4.66671 2.33398H11.3334C13.3334 2.33398 14.6667 3.33398 14.6667 5.66732V10.334C14.6667 12.6673 13.3334 13.6673 11.3334 13.6673Z" stroke="#667085" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z" stroke="#667085" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M3.66663 6.33398V9.66732" stroke="#667085" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.3334 6.33398V9.66732" stroke="#667085" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>

    );
};

export { ProfitIcon };