const DeleteModalIcon = () => {
    return (
        <svg width="74" height="67" viewBox="0 0 74 67" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.8055 66.3091H48.9017C51.5356 66.3091 54.0793 65.3277 56.0121 63.54C56.0421 63.5133 56.0689 63.49 56.0922 63.4699C67.1534 52.9396 75.5807 37.9777 73.748 22.2397C72.9418 15.3396 69.1263 8.82012 63.468 4.78422C58.01 0.89354 50.8996 -0.268157 44.3851 0.978664C40.3943 1.73978 36.8741 3.1919 34.1552 6.25637C29.2881 11.7494 26.5541 18.0286 18.7927 19.9864C15.0823 20.9228 11.2367 22.9908 8.07208 25.3443C1.31221 30.3766 -0.820906 39.7703 0.270688 47.747C1.04682 53.4052 3.48038 58.6729 7.04058 63.0794C8.69299 65.1224 11.1766 66.3091 13.8055 66.3091Z" fill="#FEE4E2" />
            <path d="M61.2597 10.8726L62.4614 10.8325C62.6517 10.8225 62.8119 10.9727 62.8169 11.163C62.822 11.3533 62.6717 11.5135 62.4815 11.5185C62.4781 11.5185 62.4748 11.5185 62.4714 11.5185H62.4614L61.2797 11.5135H61.2697C61.0894 11.5135 60.9492 11.3683 60.9492 11.193C60.9492 11.0178 61.0894 10.8776 61.2597 10.8726Z" fill="white" />
            <path d="M56.2765 11.2764C57.0877 11.1462 57.9022 11.0427 58.7201 10.9659C58.9104 10.9509 59.0756 11.0911 59.0956 11.2814C59.1106 11.4667 58.9704 11.6369 58.7852 11.657C58.7802 11.657 58.7752 11.657 58.7752 11.657C57.974 11.7137 57.1745 11.7988 56.3767 11.9123C56.2014 11.9374 56.0362 11.8172 56.0111 11.6419C55.9861 11.4667 56.1063 11.3064 56.2765 11.2764Z" fill="white" />
            <path d="M34.2657 26.4806L34.3108 26.3704L34.3608 26.2753C34.3909 26.2085 34.4209 26.1434 34.451 26.08C34.5111 25.9548 34.5762 25.8296 34.6413 25.7044C34.7064 25.5843 34.7765 25.4641 34.8416 25.3389L35.0468 24.9784C35.3273 24.4977 35.6227 24.032 35.9281 23.5763C36.549 22.665 37.225 21.7937 37.9461 20.9675C38.6671 20.1363 39.4333 19.3501 40.2444 18.6141C41.0506 17.873 41.9069 17.187 42.7982 16.5461C43.2388 16.2206 43.6995 15.9302 44.1552 15.6247L44.8562 15.1991L45.0364 15.0939L45.1216 15.0389L45.2117 14.9888L45.5722 14.7935L45.9277 14.5932L46.108 14.4931C46.1681 14.463 46.2282 14.433 46.2933 14.4029L47.0243 14.0324L47.2096 13.9422L47.3949 13.8571L47.7704 13.6919L48.141 13.5216L48.3262 13.4415L48.5165 13.3664C50.5395 12.5302 52.6375 11.9193 54.7757 11.5137C54.9609 11.4787 55.1412 11.6038 55.1813 11.7891C55.2113 11.9744 55.0961 12.1496 54.9109 12.1897C52.8278 12.6353 50.7898 13.2813 48.852 14.1375L48.6667 14.2126L48.4865 14.2978L48.131 14.473L47.7704 14.6433L47.5902 14.7284L47.4149 14.8185L46.7139 15.1941C46.6538 15.2241 46.5937 15.2542 46.5386 15.2842L46.3684 15.3844L46.0229 15.5846L45.6824 15.7849L45.5973 15.835L45.5121 15.8901L45.3419 15.9952L44.6759 16.4209C44.2403 16.7263 43.7996 17.0167 43.384 17.3422C42.5428 17.9781 41.7316 18.6591 40.9705 19.3902C40.2044 20.1213 39.4833 20.8924 38.8123 21.7036C38.1364 22.5148 37.5104 23.366 36.9446 24.2473C36.6592 24.6879 36.3888 25.1386 36.1384 25.5943L35.9482 25.9398C35.8931 26.0549 35.828 26.1701 35.7729 26.2853C35.7178 26.4004 35.6577 26.5156 35.6077 26.6358C35.5776 26.6925 35.5509 26.7493 35.5276 26.806L35.4875 26.8912L35.4575 26.9713L35.4474 26.9913C35.3072 27.3218 34.9317 27.472 34.6012 27.3368C34.2757 27.1966 34.1205 26.8211 34.2607 26.4906L34.2657 26.4806Z" fill="white" />
            <path d="M38.2953 13.2772L8.11622 25.3148L6.57898 25.9257C5.7027 26.2762 4.70624 25.8506 4.36074 24.9743C4.16545 24.4836 4.07031 23.9779 4.07031 23.4821C4.07031 21.8848 5.03172 20.3676 6.60401 19.7417L13.1986 17.1128L14.4405 16.6171L16.2932 15.876L24.3349 12.6713L26.1876 11.9303L27.0339 11.5948L31.1799 9.94236L34.0191 8.8057C36.0871 7.9845 38.4255 8.99096 39.2517 11.054C39.6022 11.9303 39.1716 12.9267 38.2953 13.2772Z" fill="#D92D20" />
            <path d="M26.1876 11.9285L24.3349 12.6695C24.0945 12.3591 23.8191 12.0787 23.5137 11.8433C23.5003 11.8333 23.487 11.8233 23.4736 11.8133C22.2568 10.902 20.6094 10.6216 19.0972 11.2274C17.9055 11.6981 17.0292 12.6295 16.5886 13.7361C16.4484 14.0916 16.3532 14.4622 16.3032 14.8377C16.2631 15.1782 16.2581 15.5287 16.2931 15.8742L14.4404 16.6153C14.3803 16.3015 14.3436 15.9877 14.3303 15.6739C14.3102 15.2183 14.3403 14.7726 14.4154 14.332C14.7809 12.1888 16.218 10.2811 18.3762 9.4198C20.8999 8.41333 23.6889 9.11936 25.4465 10.9821C25.53 11.0689 25.6101 11.159 25.6869 11.2525C25.8671 11.4661 26.034 11.6915 26.1876 11.9285Z" fill="#D92D20" />
            <path d="M16.3032 14.8399C16.2631 15.1804 16.2581 15.5309 16.2931 15.8764L14.4404 16.6175C14.3803 16.3037 14.3436 15.9899 14.3303 15.6761C14.3102 15.2204 14.3403 14.7748 14.4154 14.3341L16.5886 13.7383C16.4484 14.0938 16.3532 14.4643 16.3032 14.8399Z" fill="#B42318" />
            <path d="M25.6868 11.2539C25.8671 11.4676 26.034 11.6929 26.1876 11.9299L24.3349 12.671C24.0945 12.3605 23.8191 12.0801 23.5137 11.8448L25.6868 11.2539Z" fill="#B42318" />
            <path d="M44.1692 64.7874H17.345C15.3922 64.7874 13.7898 63.2401 13.7197 61.2872L12.548 27.0723C12.513 26.1059 13.2891 25.3047 14.2555 25.3047H47.2637C48.2301 25.3047 49.0063 26.1059 48.9712 27.0723L47.7945 61.2872C47.7294 63.2401 46.1271 64.7874 44.1692 64.7874Z" fill="#D92D20" />
            <path d="M40.0944 31.5355L39.2682 58.2996C39.2482 59.0106 39.7288 59.6165 40.3898 59.7817C40.4933 59.8084 40.5985 59.8235 40.7053 59.8268C41.5265 59.8518 42.2075 59.2109 42.2325 58.3897L43.0587 31.6256C43.0838 30.8094 42.4428 30.1234 41.6216 30.0984C41.5148 30.095 41.4096 30.1034 41.3062 30.1234C41.0207 30.1785 40.7654 30.3087 40.5601 30.504C40.2847 30.7593 40.1094 31.1249 40.0944 31.5355Z" fill="#B42318" />
            <path d="M40.0944 31.5371L39.2682 58.3012C39.2482 59.0122 39.7288 59.6181 40.3898 59.7833C41.0608 59.6581 41.5816 59.0823 41.6016 58.3713L42.4278 31.6072C42.4478 30.9011 41.9671 30.2902 41.3062 30.125C41.0207 30.1801 40.7654 30.3103 40.5601 30.5056C40.2847 30.7609 40.1094 31.1265 40.0944 31.5371Z" fill="#F04438" />
            <path d="M18.4633 31.6206L19.2094 58.3897C19.2295 59.0957 19.7452 59.6766 20.4162 59.8017C20.5163 59.8218 20.6215 59.8301 20.7317 59.8268C21.5479 59.8068 22.1938 59.1207 22.1738 58.3046L21.4277 31.5405C21.4026 30.7193 20.7216 30.0733 19.9004 30.0984C19.7936 30.1017 19.6901 30.1167 19.59 30.1434C19.3096 30.2085 19.0592 30.3587 18.8689 30.564C18.6086 30.8394 18.4533 31.21 18.4633 31.6206Z" fill="#B42318" />
            <path d="M18.4633 31.6217L19.2094 58.3908C19.2295 59.0968 19.7452 59.6777 20.4162 59.8029C21.0772 59.6426 21.5579 59.0317 21.5378 58.3257L20.7917 31.5566C20.7767 30.8456 20.256 30.2697 19.59 30.1445C19.3096 30.2096 19.0592 30.3598 18.8689 30.5651C18.6086 30.8405 18.4533 31.2111 18.4633 31.6217Z" fill="#F04438" />
            <path d="M29.252 31.5798V58.3539C29.252 59.065 29.7527 59.6608 30.4187 59.806C30.5188 59.8261 30.624 59.8361 30.7341 59.8361C31.5503 59.8361 32.2163 59.1751 32.2163 58.3539V31.5798C32.2163 30.7586 31.5503 30.0977 30.7341 30.0977C30.624 30.0977 30.5188 30.1093 30.4187 30.1327C30.1382 30.1928 29.8829 30.333 29.6876 30.5333C29.4172 30.7987 29.252 31.1692 29.252 31.5798Z" fill="#B42318" />
            <path d="M29.252 31.5799V58.354C29.252 59.0651 29.7527 59.6609 30.4187 59.8062C31.0846 59.6609 31.5854 59.0651 31.5854 58.354V31.5799C31.5854 30.8689 31.0846 30.278 30.4187 30.1328C30.1382 30.1929 29.8829 30.3331 29.6876 30.5334C29.4172 30.7988 29.252 31.1693 29.252 31.5799Z" fill="#F04438" />
            <path d="M43.2187 66.245H18.9883C18.1621 66.245 17.4961 65.579 17.4961 64.7578H44.7108C44.7108 65.579 44.0399 66.245 43.2187 66.245Z" fill="#B42318" />
            <path d="M32.4966 23.626L29.3971 21.0923C29.2619 20.9821 29.2619 20.7718 29.4071 20.6617L31.5452 19.0293C31.7054 18.9091 31.9358 18.9892 31.9808 19.1845L32.9422 23.3506C32.9973 23.606 32.7019 23.7912 32.4966 23.626Z" fill="#FF8500" />
            <path d="M41.545 22.9691L43.1474 21.6973C43.2826 21.5871 43.4879 21.6322 43.568 21.7924L44.1488 23.0392C44.234 23.2195 44.1038 23.4248 43.9035 23.4298L41.7203 23.4599C41.4599 23.4599 41.3447 23.1294 41.545 22.9691Z" fill="#FF4848" />
            <path d="M35.4422 19.7705L35.307 18.8942C35.272 18.6689 35.4572 18.4736 35.6876 18.4936L36.2784 18.5437C36.5338 18.5637 36.679 18.8391 36.5538 19.0644L36.0932 19.8856C35.9329 20.1811 35.4923 20.101 35.4422 19.7705Z" fill="#AD45FF" />
        </svg>

    );
};

export { DeleteModalIcon };