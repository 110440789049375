const ReloadIcon = () => {
    return (
        <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3317_21275)">
                <path d="M9.62533 4.58333C9.62533 4.58333 8.70638 3.33127 7.95982 2.58419C7.21326 1.83712 6.18159 1.375 5.04199 1.375C2.76382 1.375 0.916992 3.22183 0.916992 5.5C0.916992 7.77817 2.76382 9.625 5.04199 9.625C6.92258 9.625 8.50923 8.36655 9.00577 6.64583M9.62533 4.58333V1.83333M9.62533 4.58333H6.87533" stroke="#ED028C" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            </g>
            <defs>
                <clipPath id="clip0_3317_21275">
                    <rect width="11" height="11" fill="white" />
                </clipPath>
            </defs>
        </svg>

    );
};

export { ReloadIcon };