const LockedIcon = (props: any) => {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 15 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.67754 0H7.45773L8.05435 0.0688406L8.55918 0.183575L8.92633 0.298309L9.33937 0.458937L9.86715 0.711353L10.2343 0.940821L10.5556 1.17029L10.7621 1.33092L11.0145 1.53744V1.58333L11.1063 1.62923L11.3816 1.90459L11.5882 2.157L11.6341 2.24879H11.68L11.9783 2.66184L12.2536 3.09783L12.5749 3.71739L12.8043 4.31401L12.9879 4.97947L13.0797 5.46135L13.1486 6.10387V7.80193L13.1256 8.00845L13.2403 8.12319V8.16908L13.3092 8.19203V8.23792H13.3551L13.5616 8.49034L13.7452 8.74275L13.9287 9.08696L14.0894 9.54589L14.1582 10.0278V16.3841L14.0664 16.9348L13.9058 17.3708L13.6993 17.7379L13.4698 18.0362L13.378 18.151H13.3321V18.1969L13.1486 18.3575L12.8273 18.587L12.506 18.7705L12.0471 18.9312L11.657 19H2.50121L2.15701 18.9312L1.78986 18.8164L1.42271 18.6329L1.17029 18.4722L0.849034 18.1969L0.688406 18.0362L0.413044 17.6461L0.229469 17.3019L0.0917875 16.8889L0.0229469 16.5217L0 15.971L0.0229469 15.9022L0 15.8333L0.0688406 15.7645V15.7186H0.0229469V15.3514L0.0458937 15.3285H0.0229469V9.8901L0.0917875 9.52295L0.229469 9.1099L0.43599 8.71981L0.642512 8.44444L0.849034 8.21498L1.03261 8.0314L1.00966 7.6872V6.28744L1.05556 5.73672L1.1244 5.27778L1.21618 4.84179L1.39976 4.22222L1.58333 3.76329L1.83575 3.23551L2.11111 2.77657L2.27174 2.5471L2.50121 2.24879L2.70773 1.99638L3.12077 1.58333L3.37319 1.35386L3.69444 1.10145L4.03865 0.871981L4.52053 0.596618L4.97947 0.390097L5.53019 0.206522L6.12681 0.0688406L6.67754 0Z"
        fill="#A4A4A4"
      />
      <path
        d="M7.80196 8.76562L7.91669 8.78857H8.16911L8.26089 8.76562L8.28384 8.78857L11.4505 8.81152L11.7259 8.85741L12.0012 8.97215L12.2307 9.13277L12.3684 9.24751L12.552 9.49992L12.6667 9.75234L12.7355 9.98181V16.384L12.6667 16.6593L12.5061 16.9806L12.2995 17.2101L12.1389 17.3478L11.9094 17.4854L11.6111 17.5772L11.4505 17.6002H2.70775L2.3865 17.5313L2.06524 17.3707L1.85872 17.2101L1.6522 16.9577L1.49157 16.6364L1.42273 16.3151V10.1424L1.49157 9.77529L1.58336 9.56877L1.74399 9.31635L1.92756 9.13277L2.17998 8.97215L2.45534 8.85741L2.68481 8.81152L7.80196 8.78857V8.76562Z"
        fill="#F3F4F4"
      />
      <path
        d="M6.67754 1.42285H7.52658L8.0773 1.51464L8.51329 1.65232L8.85749 1.79L9.22464 1.97358L9.59179 2.20304L9.84421 2.38662L9.95894 2.50135L10.3031 2.82261L10.5326 3.07503L10.785 3.41923L10.9457 3.67164L11.1292 3.9929L11.3587 4.47478L11.5193 4.95667L11.6341 5.41561L11.7029 5.8516L11.7259 6.05812L11.7488 6.83831V7.41198H9.936V7.45788L9.79832 7.50377L9.59179 7.45788V7.43493H9.82126L9.79832 7.41198H9.33938V7.48082H9.2017L9.13286 7.41198H2.79952L2.43237 7.43493V6.21875L2.47827 5.73686L2.593 5.16319L2.75363 4.63541L2.9831 4.08469L3.25846 3.6028L3.48793 3.28155L3.78624 2.9144L4.17633 2.5243L4.42875 2.31778L4.68117 2.1342L5.02537 1.92768L5.46136 1.72116L6.01208 1.53759L6.44807 1.4458L6.67754 1.42285Z"
        fill="#F3F4F4"
      />
      <path
        d="M6.90703 10.1885H7.32007L7.59543 10.2573L7.91669 10.4179L8.215 10.6704L8.42152 10.9687L8.53626 11.267L8.58215 11.4735V11.8865L8.49036 12.2307L8.37563 12.4602L8.215 12.6667L8.12321 12.7815L8.00848 12.8733L7.98553 15.4663L7.91669 15.6957L7.77901 15.9252L7.59543 16.0858L7.36597 16.2006L7.25123 16.2235H6.92998L6.70051 16.1547L6.49399 16.017L6.33336 15.8564L6.21862 15.6269L6.17273 15.3974V12.8733L5.94326 12.6667L5.78263 12.4373L5.6679 12.2078L5.59906 11.9783L5.57611 11.6112L5.622 11.3129L5.71379 11.0605L5.85147 10.808L5.94326 10.7163L6.05799 10.6933V10.6474H6.10389V10.6015L6.05799 10.5786L6.14978 10.5556L6.17273 10.4868L6.3563 10.3721L6.63167 10.2344L6.79229 10.2114L6.86113 10.2573L6.90703 10.1885Z"
        fill="#A4A4A4"
      />
    </svg>
  );
};

export { LockedIcon };
