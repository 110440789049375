const Warning2Icon = () => {
  return (
    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10 6.96094V11.3359" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M17.5675 7.64742V13.3474C17.5675 14.2807 17.0675 15.1474 16.2591 15.6224L11.3091 18.4808C10.5008 18.9474 9.50079 18.9474 8.68412 18.4808L3.73412 15.6224C2.92579 15.1558 2.42578 14.2891 2.42578 13.3474V7.64742C2.42578 6.71409 2.92579 5.84739 3.73412 5.37239L8.68412 2.51406C9.49246 2.0474 10.4925 2.0474 11.3091 2.51406L16.2591 5.37239C17.0675 5.84739 17.5675 6.70576 17.5675 7.64742Z" stroke="#D92D20" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
      <path d="M10 14V14.0833" stroke="#D92D20" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
  );
};

export { Warning2Icon };
